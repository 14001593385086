import _invariant from "./invariant";
var exports = {};
var invariant = _invariant;
var NEUTRAL = "NEUTRAL"; // No strong direction

var LTR = "LTR"; // Left-to-Right direction

var RTL = "RTL"; // Right-to-Left direction

var globalDir = null; // == Helpers ==

/**
 * Check if a directionality value is a Strong one
 */

function isStrong(dir) {
  return dir === LTR || dir === RTL;
}
/**
 * Get string value to be used for `dir` HTML attribute or `direction` CSS
 * property.
 */


function getHTMLDir(dir) {
  !isStrong(dir) ? invariant(false) : void 0;
  return dir === LTR ? "ltr" : "rtl";
}
/**
 * Get string value to be used for `dir` HTML attribute or `direction` CSS
 * property, but returns null if `dir` has same value as `otherDir`.
 * `null`.
 */


function getHTMLDirIfDifferent(dir, otherDir) {
  !isStrong(dir) ? invariant(false) : void 0;
  !isStrong(otherDir) ? invariant(false) : void 0;
  return dir === otherDir ? null : getHTMLDir(dir);
} // == Global Direction ==

/**
 * Set the global direction.
 */


function setGlobalDir(dir) {
  globalDir = dir;
}
/**
 * Initialize the global direction
 */


function initGlobalDir() {
  setGlobalDir(LTR);
}
/**
 * Get the global direction
 */


function getGlobalDir() {
  if (!globalDir) {
    this.initGlobalDir();
  }

  !globalDir ? invariant(false) : void 0;
  return globalDir;
}

var UnicodeBidiDirection = {
  // Values
  NEUTRAL: NEUTRAL,
  LTR: LTR,
  RTL: RTL,
  // Helpers
  isStrong: isStrong,
  getHTMLDir: getHTMLDir,
  getHTMLDirIfDifferent: getHTMLDirIfDifferent,
  // Global Direction
  setGlobalDir: setGlobalDir,
  initGlobalDir: initGlobalDir,
  getGlobalDir: getGlobalDir
};
exports = UnicodeBidiDirection;
export default exports;